// Le composant n'est accessible que par les champs de formulaires, le focus ces boutons est volontairement empêché
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { format, getDate, getDay, isSameDay } from 'date-fns'

import { useIsMediumUp } from '@Hooks/useResponsive'
import Locale from '@Types/locale'
import { parseLocale } from '@Utils/i18n'
import { dateFnsLocale } from '@Utils/locale'

import { SelectionType } from '../../DatePicker.types'

import * as styles from './styles'

export type DayProps = {
  day: Date
  isInRange: boolean
  isRangeStart: boolean
  isRangeEnd: boolean
  isSelectable: boolean
  isHovered: boolean
  isFocusable?: boolean
  selectionType: SelectionType
  setHoveredDate: (day?: Date | undefined) => void
  onDaySelect: (day: Date) => void
}

const Day: FC<DayProps> = ({
  day,
  isInRange,
  isRangeStart,
  isRangeEnd,
  isSelectable,
  selectionType,
  isHovered,
  isFocusable = false,
  setHoveredDate,
  onDaySelect,
}) => {
  const theme = useTheme()
  const isMediumUp = useIsMediumUp(theme)
  const { locale } = useIntl()
  const { lang } = parseLocale(locale as Locale)

  const firstDayOfWeek = 1 // Lundi
  const firstDayPosition = ((getDay(day) + 7 - firstDayOfWeek) % 7) + 1

  const selectDay = () => {
    if (isSelectable) {
      onDaySelect(day)
    }
  }

  const hoverDayOnDesktop = () => {
    if (isMediumUp) {
      setHoveredDate(day)
    }
  }

  const unHoverDayOnDesktop = () => {
    if (isMediumUp) {
      setHoveredDate()
    }
  }

  const dayValue = getDate(day)
  const isToday = isSameDay(day, new Date())

  return (
    <button
      css={[
        styles.root(theme),
        selectionType === 'PLAIN_WITH_CIRCLE' && styles.selectedPlainWithCircle(theme),
        selectionType === 'PLAIN' && styles.selectedPlain(theme),
        selectionType === 'CIRCLE' && styles.selectedCircle,
        !isSelectable && styles.disabled(theme),
        isInRange && styles.inRange,
        isRangeStart && styles.rangeStart(theme),
        isRangeEnd && styles.rangeEnd(theme),
        isHovered && styles.hovered(theme),
        firstDayPosition > 1 && getDate(day) === 1 && styles.indent(firstDayPosition),
      ]}
      onClick={selectDay}
      onMouseEnter={hoverDayOnDesktop}
      onMouseOut={unHoverDayOnDesktop}
      disabled={!isSelectable}
      tabIndex={isFocusable ? 0 : -1}
      aria-label={format(day, `EEEE dd-MMM-yyyy`, {
        locale: dateFnsLocale[lang],
      })}
      type="button"
      data-test={format(day, 'dd-MM-yyyy')}
    >
      <Typography
        variant={isToday ? 'body1' : 'body2'}
        component="div"
        css={[styles.dayText, isToday && styles.today]}
      >{`${dayValue < 10 ? '0' : ''}${dayValue}`}</Typography>
    </button>
  )
}

export default Day
