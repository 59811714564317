import { FC, FormEvent, FormEventHandler, useCallback, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useEffectOnce } from 'react-use'
import { Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { differenceInCalendarDays, format, isValid, setHours } from 'date-fns'
import { isSdkError, Place, toPlace } from 'invictus-sdk-typescript'

import LongDistanceDatePicker from '@Components/container/datePicker/LongDistance/LongDistanceDatePicker'
import { DEFAULT_TIME } from '@DS/components/components/datePicker/DatePicker.constants'
import InfoMessageCard from '@DS/components/components/informationMessages/infoMessageCard/InfoMessageCard'
import Button from '@DS/components/forms/button/Button'
import { AutocompleteResultModel } from '@DS/components/navigation/search/atoms/AutocompleteResult'
import { ODSearchProps } from '@DS/components/navigation/search/odSearch/ODSearch'
import { useAutocompleteSdk } from '@Hooks/sdk/useAutocompleteSdk'
import { ISO_DATE_HOUR_FORMAT, parseHumanDateToDate } from '@Utils/date'
import { getDefaultMaxDate } from '@Utils/datepicker'
import { cloneObject } from '@Utils/lodash'

import { BookingWidgetParameters, FormTokens, InputField, ValidFormTokens } from '../Booking.types'

import BookingODSearchController from './BookingODSearchController'
import * as styles from './styles'

const WIDGET_BOOKING_FORM_NAME = 'WIDGET_BOOKING_FORM'

const getFormattedDate = (date: Date) => format(date, ISO_DATE_HOUR_FORMAT)

const buildUserInput = ({ originIdToken, destinationIdToken, outwardDateToken, inwardDateToken }: ValidFormTokens) => {
  const mandatoryInput = `origin_id=${originIdToken}&destination_id=${destinationIdToken}&outward_date=${outwardDateToken}`

  return inwardDateToken ? `${mandatoryInput}&inward_date=${inwardDateToken}` : mandatoryInput
}

export type BookingFormProps = BookingWidgetParameters & {
  effinityTrackingUrl?: string
}

type Fields = {
  origin?: string
  destination?: string
  outwardDate?: string
  inwardDate?: string
}

const BookingForm: FC<BookingFormProps> = ({
  destination,
  effinityTrackingUrl,
  inwardDate,
  isOneWay = false,
  isInModal = false,
  origin,
  outwardDate,
  titleIndex = 0,
  tracking,
}) => {
  const theme = useTheme()
  const { formatMessage, locale } = useIntl()
  const autocompleteSdk = useAutocompleteSdk()

  const getTrackingUrlFor = (url: string) => {
    if (!tracking) {
      return url
    }

    const { effinityCounterId, wizalyQueryParameters } = tracking

    let urlToReturn = url

    if (effinityCounterId) {
      urlToReturn = `${effinityTrackingUrl}?id_compteur=${effinityCounterId}&url=${url}`
    }

    if (wizalyQueryParameters) {
      urlToReturn = `${urlToReturn}&${wizalyQueryParameters}`
    }

    return urlToReturn
  }

  const { defaultValue: outwardDateDefaultValue, isDisabled: isOutwardDateDisabled } = outwardDate ?? {}
  const { defaultValue: inwardDateDefaultValue, isDisabled: isInwardDateDisabled } =
    !isOneWay && inwardDate ? inwardDate : ({} as InputField)
  const isOriginDisabled = !!origin?.isDisabled
  const isDestinationDisabled = !!destination?.isDisabled

  const getDatePickerMode = () => {
    if (isOneWay) {
      return 'OUTWARD_ONLY'
    }

    if (isOutwardDateDisabled) {
      return 'INWARD'
    }

    if (isInwardDateDisabled) {
      return 'OUTWARD'
    }

    return 'ROUND_TRIP'
  }

  const titles = [
    formatMessage({ id: 'widget_external_title0' }),
    formatMessage({ id: 'widget_external_title1' }),
    formatMessage({ id: 'widget_external_title2' }),
    formatMessage({ id: 'widget_external_title3' }),
  ]

  const [originValue, setOriginValue] = useState<Place | undefined>(undefined)
  const [destinationValue, setDestinationValue] = useState<Place | undefined>(undefined)

  useEffectOnce(() => {
    const getAutocompleteItems = async (
      input: InputField | undefined,
      onSuccess: (value: Place | undefined) => void
    ) => {
      if (input) {
        const result = await autocompleteSdk.autocomplete(input.defaultValue, {
          keepStationsOnly: false,
          usage: 'STATIONS_CITIES_RESARAIL',
          returnsSuggestions: false,
        })

        if (result.places.transportPlaces.length > 0) {
          onSuccess(toPlace(result.places.transportPlaces[0]))
        }
      }
    }
    getAutocompleteItems(origin, setOriginValue)
    getAutocompleteItems(destination, setDestinationValue)
  })

  const parsedOutwardDate = parseHumanDateToDate(outwardDateDefaultValue)
  const [outwardDateValue, setOutwardDateValue] = useState<Date>(
    parsedOutwardDate ? setHours(parsedOutwardDate, DEFAULT_TIME) : new Date()
  )

  const parsedInwardDate = parseHumanDateToDate(inwardDateDefaultValue)
  const [inwardDateValue, setInwardDateValue] = useState<Date | undefined>(
    parsedInwardDate ? setHours(parsedInwardDate, DEFAULT_TIME) : undefined
  )

  const [
    { inwardDate: inwardDateError, outwardDate: outwardDateError, origin: originError, destination: destinationError },
    setErrorFields,
  ] = useState<Fields>({ origin: undefined })
  const [autocompleteErrorMessage, setAutocompleteErrorMessage] = useState<string>()
  const [submittedOnce, setSubmittedOnce] = useState(false)

  const hasError = originError || destinationError || outwardDateError || inwardDateError

  const ORIGIN_FIELD_ID = 'sc_widgetBooking_origin'
  const DESTINATION_FIELD_ID = 'sc_widgetBooking_destination'
  const OUTWARD_DATE_FIELD_ID = 'sc_widgetBooking_outwardDate'
  const INWARD_DATE_FIELD_ID = 'sc_widgetBooking_inwardDate'

  const errorArray = [inwardDateError, outwardDateError, originError, destinationError]

  const valueFromArrayNotUndefined = (value?: string) => value !== undefined

  const updateOrigin = (result: AutocompleteResultModel | undefined) =>
    setOriginValue(result ? toPlace(result) : undefined)

  const updateDestination = (result: AutocompleteResultModel | undefined) =>
    setDestinationValue(result ? toPlace(result) : undefined)

  const updateSearchDate = (outwardDateTmp: Date, inwardDateTmp?: Date) => {
    setOutwardDateValue(outwardDateTmp)
    setInwardDateValue(inwardDateTmp || undefined)
  }

  const onInwardDateClear = () => {
    setInwardDateValue(undefined)
  }

  const onSwap: ODSearchProps['onSwap'] = () => {
    const oldDestination = destinationValue && cloneObject(destinationValue)
    setDestinationValue(originValue)
    setOriginValue(oldDestination)
  }

  const checkForm = useCallback((): FormTokens => {
    const checkDatesChronology = (_outwardDate: Date, _inwardDate: Date) =>
      isValid(_outwardDate) && isValid(_inwardDate) && differenceInCalendarDays(_inwardDate, _outwardDate) < 0
        ? formatMessage({ id: 'datepicker_error_chronologyError' })
        : undefined

    const dateValidator = (
      date: Date,
      idFieldName: 'widget_external_outward_field' | 'widget_external_inward_field'
    ) => {
      const today = new Date()
      const fieldName = formatMessage({ id: idFieldName })

      if (!isValid(date)) {
        return formatMessage({ id: 'formInput_date_invalid' }, { fieldName })
      }

      if (differenceInCalendarDays(date, today) < 0) {
        return formatMessage({ id: 'formInput_date_pastDate' }, { fieldName })
      }

      if (differenceInCalendarDays(date, getDefaultMaxDate()) > 0) {
        return formatMessage({ id: 'formInput_date_tooFar' }, { fieldName })
      }

      return undefined
    }

    const checkOutwardDate = () => dateValidator(outwardDateValue, 'widget_external_outward_field')
    const checkInwardDate = () =>
      inwardDateValue &&
      (dateValidator(inwardDateValue, 'widget_external_inward_field') ||
        checkDatesChronology(outwardDateValue, inwardDateValue))

    const validateFields: Fields = {
      origin: originValue?.label ? undefined : formatMessage({ id: 'formInput_origin_empty' }),
      destination: destinationValue?.label ? undefined : formatMessage({ id: 'formInput_destination_empty' }),
    }

    const outwardDateErrorResult = checkOutwardDate()
    const inwardDateErrorResult = checkInwardDate()

    if (!validateFields.outwardDate && !!outwardDateErrorResult) {
      validateFields.outwardDate = outwardDateErrorResult
    }

    if (!isOneWay && inwardDateErrorResult) {
      validateFields.inwardDate = inwardDateErrorResult
    }

    setErrorFields(validateFields)

    const isFormValid = !!(
      originValue?.label &&
      destinationValue?.label &&
      !outwardDateErrorResult &&
      (isOneWay || !inwardDateValue || !inwardDateErrorResult)
    )

    if (isFormValid) {
      const formattedOutwardDate = getFormattedDate(outwardDateValue)
      const formattedInwardDate = inwardDateValue && getFormattedDate(inwardDateValue)

      return {
        isFormValid,
        originIdToken: originValue.id,
        destinationIdToken: destinationValue.id,
        outwardDateToken: formattedOutwardDate,
        inwardDateToken: formattedInwardDate,
      }
    }

    return {
      isFormValid: false,
    }
  }, [
    destinationValue?.id,
    destinationValue?.label,
    formatMessage,
    inwardDateValue,
    isOneWay,
    originValue?.id,
    originValue?.label,
    outwardDateValue,
  ])

  useEffect(() => {
    if (submittedOnce) {
      checkForm()
    }
  }, [outwardDateValue, destinationValue, checkForm, originValue?.label, inwardDateValue, submittedOnce])

  const onSubmit: FormEventHandler<HTMLFormElement> = (
    event: FormEvent<HTMLFormElement> & {
      target: HTMLButtonElement
    }
  ) => {
    event.preventDefault()

    // The widget and the dialog components have both a form with a submit button.
    // To be sure of the origin of the submit action, the widget form has a name.
    // If the name is not those of the widget form name, we do not continue.
    if (event.target.name !== WIDGET_BOOKING_FORM_NAME) {
      return
    }

    setSubmittedOnce(true)

    const formTokens = checkForm()

    if (formTokens.isFormValid) {
      const userInput = buildUserInput(formTokens)
      window.open(
        getTrackingUrlFor(`https://www.sncf-connect.com/app/${locale}/redirect?redirection_type=SEARCH&${userInput}`)
      )
    }
  }

  const handleAutocompleteError = (error: unknown) => {
    if (isSdkError(error)) {
      setAutocompleteErrorMessage(error.displayedMessage ?? formatMessage({ id: 'connectivityError' }))
    }
  }

  const resetAutocompleteError = () => {
    setAutocompleteErrorMessage(undefined)
  }

  return (
    <div css={styles.sncfConnectWidgetBookingContainer(theme)}>
      <form onSubmit={onSubmit} name={WIDGET_BOOKING_FORM_NAME} css={styles.sncfConnectWidgetBookingForm} noValidate>
        <Typography component="h1" css={styles.sncfConnectWidgetBookingFormTitle} variant="h2">
          {titles[titleIndex]}
        </Typography>
        {!!autocompleteErrorMessage && (
          <InfoMessageCard
            description={autocompleteErrorMessage}
            severity="alert"
            hasToDisplayFullDescription
            css={styles.sncfConnectErrorMessage}
          />
        )}
        {submittedOnce && hasError && (
          <InfoMessageCard
            hasToDisplayFullDescription
            title={formatMessage(
              { id: 'formInput_errors' },
              { count: errorArray.filter(valueFromArrayNotUndefined).length }
            )}
            description={
              <ul>
                {originError && <li id={ORIGIN_FIELD_ID}>{originError}</li>}
                {destinationError && <li id={DESTINATION_FIELD_ID}>{destinationError}</li>}
                {outwardDateError && <li id={OUTWARD_DATE_FIELD_ID}>{outwardDateError}</li>}
                {inwardDateError && <li id={INWARD_DATE_FIELD_ID}>{inwardDateError}</li>}
              </ul>
            }
            severity="alert"
            css={styles.sncfConnectErrorMessage}
          />
        )}
        <BookingODSearchController
          css={styles.bookingODSearchController}
          onAutocompleteError={handleAutocompleteError}
          onClearOrigin={() => updateOrigin(undefined)}
          onClearDestination={() => updateDestination(undefined)}
          destinationAutocompleteProps={{
            onSelectItem: updateDestination,
          }}
          destinationInputPlaceProps={{
            error: !!destinationError && submittedOnce,
            disabled: isDestinationDisabled,
            onChange: resetAutocompleteError,
            'aria-describedby': DESTINATION_FIELD_ID,
          }}
          destinationInputValue={destinationValue?.label || ''}
          usage="STATIONS_CITIES_RESARAIL"
          hasToRestoreSelectedOnBlur
          originAutocompleteProps={{
            onSelectItem: updateOrigin,
          }}
          originInputPlaceProps={{
            error: !!originError && submittedOnce,
            disabled: isOriginDisabled,
            onChange: resetAutocompleteError,
            'aria-describedby': ORIGIN_FIELD_ID,
          }}
          originInputValue={originValue?.label || ''}
          onSwap={onSwap}
          shouldIgnoreSuggestions
          isSwapHidden={isOriginDisabled || isDestinationDisabled}
        />
        <div css={styles.sncfConnectDateContainer}>
          <LongDistanceDatePicker
            autoFocus={!!originValue?.label && !!destinationValue?.label}
            initStartDate={{ date: outwardDateValue }}
            initEndDate={!isOneWay && inwardDateValue ? { date: inwardDateValue } : undefined}
            mode={getDatePickerMode()}
            onDateChange={updateSearchDate}
            onClear={onInwardDateClear}
            isInModal={isInModal}
          />
        </div>
        <div css={styles.validateButton}>
          <Button type="submit">
            <FormattedMessage id="selectTravelers_pricesButton" />
          </Button>
        </div>
      </form>
    </div>
  )
}

export default BookingForm
