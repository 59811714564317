export enum SESSION {
  autocompleteTutorialDisplay = 'autocompleteTutorialDisplay',
  cancellationInit = 'cancellationInit',
  catalogDiscountCardOffersList = 'catalogDiscountCardOffersList',
  catalogDiscountCardOfferId = 'catalogDiscountCardOfferId',
  catalogTerApiOffersRequestParams = 'catalogTerApiOffersRequestParams',
  confirmation = 'confirmation',
  destination = 'destination',
  discountCard = 'discountCard',
  exchangeBuyerEmail = 'exchangeBuyerEmail',
  exchangeDestination = 'exchangeDestination',
  exchangeInwardSchedule = 'exchangeInwardSchedule',
  exchangeJourneyIds = 'exchangeJourneyIds',
  exchangeJourneyToExchange = 'exchangeJourneyToExchange',
  exchangeOrigin = 'exchangeOrigin',
  exchangeOutwardSchedule = 'exchangeOutwardSchedule',
  exchangeSelectedOutwardId = 'exchangeSelectedOutwardId',
  exchangeTravelers = 'exchangeTravelers',
  finalizationId = 'finalizationId',
  flagshipInitialCampaigns = 'flagshipInitialCampaigns',
  itineraryId = 'itineraryId',
  origin = 'origin',
  paymentMeanType = 'paymentMeanType',
  pushedTripBookingRequest = 'pushedTripBookingRequest',
  rideHailingSearch = 'rideHailingSearch',
  rideHailingBooking = 'rideHailingBooking',
  searchContext = 'searchContext',
  trip = 'trip',
  tripPass = 'tripPass',
  tripV3 = 'tripV3',
}
