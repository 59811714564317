import { forwardRef, ReactElement, ReactNode } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { AppBar as MaterialAppBar, Container, IconButton, Toolbar, Typography } from '@mui/material'
import { ThemeProvider, useTheme } from '@mui/material/styles'

import LinkButton from '@DS/components/forms/links/linkButton/LinkButton'
import ConditionalWrapper from '@DS/components/utils/wrapper/ConditionalWrapper'
import { useIsMediumUp } from '@Hooks/useResponsive'
import { darkTheme } from '@Material/theme'
import { ReactComponent as ArrowBack } from '@Svg/arrow_back.svg'

import * as styles from './styles'

type CloseAction =
  | {
      isInModal: true
      onCloseButtonClick?: VoidFunction
      actionLabel?: string
    }
  | {
      isInModal?: false
      onCloseButtonClick?: never
      actionLabel?: never
    }

export type AppBarProps = CloseAction & {
  children?: ReactElement
  onBackButtonClick?: VoidFunction
  title: ReactNode
  withBookingForm?: boolean
}

const AppBar = forwardRef<HTMLDivElement, AppBarProps>(
  (
    { actionLabel, children, isInModal = false, onBackButtonClick, onCloseButtonClick, title, withBookingForm = false },
    ref
  ) => {
    const { formatMessage } = useIntl()
    const theme = useTheme()
    const isDesktop = useIsMediumUp(theme)

    const getVariantToApply = () => {
      if (isInModal) {
        return 'h3'
      }

      return isDesktop ? 'h2' : 'h3'
    }

    return (
      <ThemeProvider theme={darkTheme}>
        <MaterialAppBar
          css={children ? styles.appBarProminent(theme, isInModal) : styles.appBar(theme, isInModal)}
          color="primary"
          elevation={0}
          position="sticky"
          ref={ref}
        >
          <ConditionalWrapper
            condition={!isInModal}
            wrapper={(wrapperChildren) => (
              <Container maxWidth="xl" css={styles.appBarContainer(theme)}>
                <>{wrapperChildren}</>
              </Container>
            )}
          >
            <>
              <Toolbar css={styles.toolBar(theme)}>
                {onBackButtonClick && (
                  <IconButton
                    aria-label={formatMessage({ id: 'appBar_backButton_ariaLabel' })}
                    color="inherit"
                    css={[styles.backButton(darkTheme), withBookingForm && styles.backButtonWithBookingForm]}
                    data-test="back-button"
                    disableFocusRipple
                    edge="start"
                    onClick={onBackButtonClick}
                    data-cs-override-id="fleche_retour"
                  >
                    <ArrowBack css={styles.arrowIcon(darkTheme)} />
                  </IconButton>
                )}
                <Typography
                  css={styles.content}
                  variant={getVariantToApply()}
                  component="h1"
                  id={isInModal ? 'modal-header-title' : undefined}
                >
                  {title}
                </Typography>
                {isInModal && onCloseButtonClick && (
                  <LinkButton
                    onClick={onCloseButtonClick}
                    css={styles.appBarAction}
                    aria-label={formatMessage({ id: 'close_dialog_ariaLabel' })}
                    data-cs-override-id="fermer"
                  >
                    {actionLabel || <FormattedMessage id="close" />}
                  </LinkButton>
                )}
              </Toolbar>
              {children && <div css={styles.children}>{children}</div>}
            </>
          </ConditionalWrapper>
        </MaterialAppBar>
      </ThemeProvider>
    )
  }
)

export default AppBar
