import { v4 as uuid } from 'uuid'

import { StringExpirableData, StringExpirableDataMapper } from './tracking/expirableData'
import { canUseDom } from './utils/canUse'

// STORAGE KEYS
export const EMAIL_HIDDEN_STORAGE = 'ivts-emailHidden'
export const EMAIL_STRONG_STORAGE = 'ivts-emailStrong'
export const EMAIL_STRONGER_STORAGE = 'ivts-emailStronger'
export const NAVIGATION_PREVIOUS_STORAGE = 'ivts-previousPageDatas'
export const VISITOR_TYPE_STORAGE = 'ivts-visitorType'
export const FIRST_VISIT_STORAGE = 'ivts-firstVisit'
export const EVENT_TO_CONSENT_STORAGE = 'ivts-event-pending'
export const EVENTS_PUSH_STORAGE = 'ivts-events-push'
export const EVENTS_CLICK_STORAGE = 'ivts-events-click'
export const TRACE_ID_STORAGE = 'ivts-trace-id'
export const CONSENT_VENDORS_S2S_STORAGE = 'ivts-con-v'
export const CONSENT_VENDORS_ALL_STORAGE = 'ivts-con-v-all'
export const CONSENT_VENDORS_FILTER_STORAGE = 'ivts-con-v-filter'
export const CONSENT_STRING_STORAGE = 'ivts-con-s'
export const CONSENT_ID_STORAGE = 'ivts-con-id'
export const EXEMPT_ID_STORAGE = 'ivts-con-ex'
export const ATTRIBUTION_MEDIUM_STORAGE = 'attribution-medium'
export const ATTRIBUTION_SOURCE_STORAGE = 'attribution-source'
export const ATTRIBUTION_CAMPAIGN_STORAGE = 'attribution-campaign'
export const ATTRIBUTION_CONTENT_STORAGE = 'attribution-content'
export const ATTRIBUTION_TERM_STORAGE = 'attribution-term'
export const ATTRIBUTION_CRM_STORAGE = 'attribution-crm'
export const ATTRIBUTION_CLID_STORAGE = 'attribution-clid'
export const ATTRIBUTION_GCLID_STORAGE = 'attribution-gclid'
export const ITINERARY_ID_STORAGE = 'itineraryId'
export const CRITEO_ID = 'ivts-criteo-id'
export const ZEMANTA_ID_STORAGE = 'ivts-zemanta-id'
export const SEARCH_USAGE_STORAGE = 'ivts-search-usage'
export const SESSION_ID_STORAGE = 'ivts-session-id'

export class StorageSdk {
  localStorage = (): Storage | null => (canUseDom() ? localStorage : null)

  sessionStorage = (): Storage | null => (canUseDom() ? sessionStorage : null)

  getExpirableStringFromLocal(key: string): StringExpirableData | undefined {
    return this.getExpirableString(key, this.localStorage())
  }

  getExpirableStringFromSession(key: string): StringExpirableData | undefined {
    return this.getExpirableString(key, this.sessionStorage())
  }

  private getExpirableString(key: string, storage: Storage | null): StringExpirableData | undefined {
    const data = StringExpirableDataMapper.fromJSON(storage?.getItem(key))

    if (data?.isExpired()) {
      storage?.removeItem(key)

      return undefined
    }

    return data
  }

  getSessionId(): string {
    const current = StringExpirableDataMapper.fromJSON(this.localStorage()?.getItem(SESSION_ID_STORAGE))

    if (current.isExpired() || current.value === '') {
      this.resetSessionId()
    }

    const currentSplited = current.value.split('|')
    this.saveSessionId(currentSplited[0])

    return currentSplited[0]
  }

  resetSessionId(): string {
    const newUuid = uuid()
    this.saveSessionId(newUuid)

    return newUuid
  }

  saveSessionId(newUuid: string): void {
    this.localStorage()?.setItem(
      SESSION_ID_STORAGE,
      StringExpirableDataMapper.toJSON(new StringExpirableData(`${newUuid}|${Date.now()}`))
    )
  }
}
