import { CSSProperties } from 'react'
import { css, SerializedStyles } from '@emotion/react'
import { Theme } from '@mui/material/styles'
import { rem, size } from 'polished'

import { MEDIUM_RADIUS, STANDARD_RADIUS } from '@DS/styles/constants/radius'
import { spacing, SPACING_1, SPACING_2, SPACING_3 } from '@DS/styles/constants/spacing'
import { typographyOverrides } from '@Material/typography'
import { highContrast } from '@Styles/a11y'
import { getFocusVisibleStyle } from '@Styles/focus'

import { IconPosition } from './Buttons.types'

export const buttonIcon = css({
  fill: 'currentColor',
  ...size(rem(20)),
})

const buttonContainer = css({
  maxWidth: rem(288),
  minHeight: rem(48),
  minWidth: rem(156),
})

const storeButtonContainer = css({
  maxWidth: rem(156),
  minHeight: rem(48),
  minWidth: rem(124),
})

export const button = (theme: Theme): SerializedStyles =>
  css(
    {
      ...highContrast({
        border: 'solid 2px transparent',
      }),
      borderRadius: MEDIUM_RADIUS,
      padding: `${SPACING_1} ${SPACING_3}`,
      textTransform: 'none',
      whiteSpace: 'break-spaces',
      ...(typographyOverrides(theme)?.body1 as CSSProperties),
    },
    buttonContainer
  )

export const buttonLinkCard = (theme: Theme, color: string = theme.palette.primary.main): SerializedStyles =>
  css({
    '&:hover, &:focus, &:active': {
      backgroundColor: theme.customPalette.onElements.selected,
    },
    alignItems: 'center',
    color,
    display: 'flex',
    justifyContent: 'center',
  })

export const buttonLinkCardIcon = (
  theme: Theme,
  color: string = theme.palette.primary.main,
  position: IconPosition = 'left'
): SerializedStyles =>
  css({
    fill: color,
    [position === 'left' ? 'marginRight' : 'marginLeft']: SPACING_1,
    ...size(rem(16)),
    '.arrow_circle-arrow': {
      fill: theme.palette.primary.contrastText,
    },
  })

export const onBackground = (theme: Theme, isPrimary: boolean): SerializedStyles =>
  css({
    '&:focus-visible::before': getFocusVisibleStyle(
      isPrimary ? theme.palette.primary.contrastText : theme.palette.primary.main,
      MEDIUM_RADIUS
    ),
    '&:hover, &:focus:not(:focus-visible)': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    },
    ...(!isPrimary && {
      borderColor: theme.palette.primary.main,
      ...highContrast({
        borderColor: 'ButtonBorder',
      }),
    }),
    ...(!isPrimary &&
      highContrast({
        borderColor: 'buttonBorder',
      })),
  })

export const onAlternative = (theme: Theme, isPrimary: boolean): SerializedStyles =>
  css({
    '&:focus-visible::before': getFocusVisibleStyle(
      isPrimary
        ? theme.customPalette.alternative.onAlternativeColor2
        : theme.customPalette.alternative.onAlternativeColor1,
      MEDIUM_RADIUS
    ),
    '&:hover, &:focus:not(:focus-visible)': {
      backgroundColor: theme.customPalette.onElements.selected,
      color: theme.customPalette.alternative.onAlternativeColor1,
      ...(!isPrimary && {
        borderColor: theme.customPalette.alternative.onAlternativeColor1,
      }),
    },
    ...(isPrimary && { backgroundColor: theme.customPalette.alternative.onAlternativeColor1 }),
    ...(!isPrimary && {
      borderColor: theme.customPalette.alternative.onAlternativeColor1,
      color: theme.customPalette.alternative.onAlternativeColor1,
    }),
  })

export const onHeader = (theme: Theme, isPrimary: boolean): SerializedStyles =>
  css({
    '&:focus-visible::before': getFocusVisibleStyle(
      isPrimary
        ? theme.customPalette.alternative.onAlternativeColor1
        : theme.customPalette.alternative.onAlternativeColor2,
      MEDIUM_RADIUS
    ),
    '&:hover, &:focus:not(:focus-visible)': {
      backgroundColor: theme.customPalette.alternative.onAlternativeColor1,
      color: theme.customPalette.alternative.onAlternativeColor2,
      ...(!isPrimary && {
        borderColor: theme.customPalette.alternative.onAlternativeColor2,
      }),
    },
    ...(isPrimary && {
      backgroundColor: theme.customPalette.alternative.onAlternativeColor2,
      color: theme.customPalette.alternative.onAlternativeColor1,
    }),
    ...(!isPrimary && {
      borderColor: theme.customPalette.alternative.onAlternativeColor2,
      color: theme.customPalette.alternative.onAlternativeColor2,
    }),
  })

export const loadingWrapper = css(
  {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: rem(160),
  },
  buttonContainer
)

export const arrowLink = (theme: Theme): SerializedStyles =>
  css({
    '&:focus-visible::before': getFocusVisibleStyle(theme.palette.primary.main, STANDARD_RADIUS),
    '&:hover': {
      backgroundColor: theme.customPalette.onElements.selected,
    },
    color: theme.palette.text.primary,
    padding: SPACING_2,
    textDecoration: 'none',
    textTransform: 'none',
    width: '100%',
  })

export const buttonsGroup = css({
  alignItems: 'center',
  bottom: 0,
  display: 'flex',
  flexDirection: 'row-reverse',
  flexWrap: 'wrap',
  justifyContent: 'center',
  padding: `${SPACING_3} 0`,
  // TODO: gap: SPACING_2 et padding: `${SPACING_4} 0`
})

export const buttonsGroupInACard = css({
  margin: `-${SPACING_1}`, // TODO: à retirer quand gap sera mis sur buttonGroup
  padding: `${SPACING_1} 0 0`,
})

export const fullWidth = css({
  width: '100%',
})

export const customWidth = (width: number): SerializedStyles =>
  css({
    width: rem(width),
  })

export const buttonInGroup = css({
  // TODO: retirer avec gap
  margin: SPACING_1,
})

export const bottomActions: SerializedStyles = css({
  padding: `${SPACING_2} ${spacing(1.5)}`,
})

export const storeButton = (theme: Theme, isElevated: boolean): SerializedStyles =>
  css(
    {
      '&:focus': {
        backgroundColor: isElevated ? theme.customPalette.onElements.elevated : theme.palette.background.paper,
      },
      '&:focus-visible::before': getFocusVisibleStyle(theme.palette.primary.main, MEDIUM_RADIUS),
      '&:hover, &:active': {
        backgroundColor: theme.customPalette.onElements.selected,
        color: theme.customPalette.invertedColors.invertedColorsSurface,
      },
      backgroundColor: theme.palette.background.paper,
      borderColor: theme.palette.background.paper,
      borderRadius: MEDIUM_RADIUS,
      color: theme.customPalette.invertedColors.invertedColorsSurface,
      maxWidth: 'unset',
      padding: 0,
      width: '100%',
      ...(isElevated && {
        backgroundColor: theme.customPalette.onElements.elevated,
      }),
    },
    storeButtonContainer
  )

export const storeImage = (isIcon: boolean): SerializedStyles =>
  css(
    isIcon
      ? {
          fill: 'currentColor',
          height: rem(20),
        }
      : {
          height: rem(48),
        }
  )
