import { ComponentPropsWithRef, ElementType, ReactElement, ReactNode } from 'react'

import { ReactComponent as ArrowCircleRightIcon } from '@Svg/navigationAndAction/arrow_circle_right.svg'

import LinkBase from '../_internal/LinkBase'
import { LinkBaseProps } from '../_internal/LinkBase.types'

import * as styles from './styles'

export type LinkButtonProps<Component extends ElementType = 'button'> = Omit<
  LinkBaseProps<Component>,
  'underline' | 'locale' | 'isBasePath' | 'isInline' | 'hasNoSpacing' | 'hasDefaultFocusVisible' | 'isObfuscated'
> & {
  component?: Component
  withIcon?: boolean
  isUnderline?: boolean
  isExternal?: boolean
  isObfuscated?: boolean
  children?: ReactNode
  href?: string
} & ComponentPropsWithRef<Component>

const LinkButton = <Component extends ElementType = 'button'>({
  withIcon = false,
  isUnderline = false,
  children,
  ...rest
}: LinkButtonProps<Component>): ReactElement | null => {
  const component = rest.component || 'button'

  return (
    <LinkBase
      {...rest}
      css={styles.linkButton}
      component={component}
      underline={isUnderline ? 'always' : 'none'}
      {...(component === 'button' && { type: 'button' })}
      {...(withIcon && { icon: ArrowCircleRightIcon })}
    >
      {children}
    </LinkBase>
  )
}

export default LinkButton
