export enum QUERY_KEY {
  getAlert = 'getAlert',
  getMaxAlerts = 'getMaxAlerts',
  getFullTrainAlerts = 'getFullTrainAlerts',
  searchAutocomplete = 'search-autocomplete',
  getStationTimetableState = 'getStationTimetableState',
  getTrafficInfo = 'getTrafficInfo',
  getXSellProducts = 'getXSellProducts',
  getXSellProductsForTrip = 'getXSellProductsForTrip',
  searchAutocompleteSuggestions = 'search-autocomplete-suggestions',
  splitPaymentSchedule = 'splitPaymentSchedule',
  vehicleDetails = 'vehicleDetails',
  salesOpeningAlerts = 'salesOpeningAlerts',
  terAvailableSeats = 'terAvailableSeats',
  tripPass = 'tripPass',
  getCountries = 'getCountries',
  trip = 'trip',
  tripList = 'trip-list',
  getPricingSncfBaseInformation = 'getPricingSncfBaseInformation',
  getPricingTerBaseInformation = 'getPricingTerBaseInformation',
  selectableDestinations = 'selectableDestinations',
  getBookExchange = 'getBookExchange',
  getFinalization = 'getFinalization',
  getGoogleWallet = 'getGoogleWallet',
  getCatalogPricingOffers = 'getCatalogPricingOffers',
  getSearchInwardExchange = 'getSearchInwardExchange',
  getSearchOutwardExchange = 'getSearchOutwardExchange',
  updateSearchExchange = 'updateSearchExchange',
  addCompanionFromContact = 'addCompanionFromContact',
  rideHailingSearchInfos = 'rideHailingSearchInfos',
  rideHailingEstimate = 'rideHailingEstimate',
  rideHailingOrders = 'rideHailingOrders',
  rideHailingOrder = 'rideHailingOrder',
  getTripFilter = 'getTripFilter',
  getProofOfPurchasePage = 'getProofOfPurchasePage',
  initFirstClassUpsellExchange = 'initFirstClassUpsellExchange',
  getAdditionalServicesCancellation = 'getAdditionalServicesCancellation',
  getAdditionalServices = 'getAdditionalServices',
}
