import { FC, forwardRef, HTMLAttributes, ReactElement } from 'react'
import { IMaskMixin } from 'react-imask'
import { ReactMaskProps } from 'react-imask/dist/mixin'
import { TextField, TextFieldProps } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { format, isValid, parse } from 'date-fns'
import { AnyMaskedOptions, MaskedRange } from 'imask'

import { humanDateFormat } from '@Constants/date'
import ClearButton from '@DS/components/forms/input/ClearButton'
import InputHelperText from '@DS/components/forms/input/InputHelperText'
import * as styles from '@DS/components/forms/input/styles'
import useUuid from '@Hooks/useUuid'
import { DateFormat } from '@Types/date'
import { getPattern } from '@Utils/date'

/*
 * TODO IVTS-21796
 * En attendant un correctif pour
 * - https://github.com/uNmAnNeR/imaskjs/issues/639
 * - https://github.com/uNmAnNeR/imaskjs/issues/654
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MaskedTextField = IMaskMixin((props) => <TextField {...(props as any)} />) as FC<
  TextFieldProps & Partial<AnyMaskedOptions> & Partial<Omit<ReactMaskProps, 'inputRef'>>
>

export type InputDateProps = Omit<TextFieldProps, 'onChange' | 'label'> & {
  error?: boolean
  isEndAdornmentHidden?: boolean
  name: string
  value?: string
  readOnly?: boolean
  buttonAriaLabel?: string
  onClear?: VoidFunction
  onChange: (date: string) => void
  dateFormat?: DateFormat
  adornmentIcon?: ReactElement
  label?: string
}

const InputDate: FC<InputDateProps> = forwardRef<unknown, InputDateProps>(
  (
    {
      error = false,
      isEndAdornmentHidden = false,
      name,
      value = '',
      readOnly = false,
      buttonAriaLabel,
      onClear,
      onChange,
      'aria-required': ariaRequired,
      dateFormat = humanDateFormat,
      adornmentIcon: AdornmentIcon,
      ...rest
    },
    ref
  ) => {
    const id = useUuid()
    const theme = useTheme()

    const disabledClassNameProps = readOnly && { className: 'Mui-disabled' }

    const formatDate = (date: Date) => {
      if (isValid(date)) {
        return format(date, dateFormat)
      }

      return '' // Retour d'une chaîne vide importante pour ne pas provoquer d'erreur côté iMask
    }

    const parseDate = (str: string) => parse(str, dateFormat, new Date())
    const pattern = getPattern(dateFormat)
    const isHumanDate = dateFormat === humanDateFormat

    const getRightIcon = () => {
      if (isEndAdornmentHidden) return undefined

      if (AdornmentIcon) return AdornmentIcon

      if (!rest.disabled && !readOnly && value && onClear) {
        return <ClearButton ariaLabel={buttonAriaLabel} name={name} onClear={onClear} />
      }

      return undefined
    }

    return (
      <div css={styles.inputWrapper}>
        {
          // @ts-expect-error TODO IVTS-21796
          <MaskedTextField
            {...rest}
            {...disabledClassNameProps}
            css={styles.input(theme, error, !!value, rest.disabled)}
            id={name ? `input-date-${name}` : rest.id ?? `${id}-input-date`}
            blocks={{
              Y: {
                mask: MaskedRange,
                maxLength: 4,
                autofix: false,
                from: 0,
                to: 9999,
                lazy: false,
              },
              m: {
                mask: MaskedRange,
                maxLength: 2,
                autofix: false,
                from: 1,
                to: 12,
                lazy: false,
              },
              ...(isHumanDate && {
                d: {
                  mask: MaskedRange,
                  maxLength: 2,
                  autofix: false,
                  from: 1,
                  to: 31,
                  lazy: false,
                },
              }),
            }}
            error={error}
            format={formatDate}
            FormHelperTextProps={
              {
                ...disabledClassNameProps,
                'data-test': `${name}-input-date-text-helper`,
                style: styles.overrideHelperText,
              } as HTMLAttributes<HTMLDivElement>
            }
            helperText={error && <InputHelperText hasError={error} helperText={rest.helperText} />}
            InputLabelProps={disabledClassNameProps || undefined}
            InputProps={{
              ...disabledClassNameProps,
              endAdornment: getRightIcon(),
              readOnly,
              value,
            }}
            inputProps={{
              ...(ariaRequired && { 'aria-required': ariaRequired }),
            }}
            inputRef={ref}
            mask={Date}
            name={name}
            parse={parseDate}
            pattern={pattern}
            type="tel"
            value={value}
            variant="outlined"
            onAccept={onChange}
            onComplete={onChange}
          />
        }
      </div>
    )
  }
)

export default InputDate
